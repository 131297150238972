import BaseController from "./base_controller"
import axios from "axios"
export default class extends BaseController {

  initialize() {
    console.log('hello from vacancies show controller')
  }

  showForm(e){
    e.preventDefault();
    document.querySelectorAll('td.editing').forEach(function (editing_td) {
      editing_td.classList.remove('editing');
    });
    e.target.closest('td').classList.add('editing');
  }

  sendMail(e){
    e.preventDefault();
    console.log("sendMail");
    let url = e.target.href;
    axios.get(url)
      .then(function (response) {
        $('.flash-message').append(response.data).delay().fadeIn();
      })
      .catch(function (error) {
        console.log(error);
      });
    this.hideFlash();
  }

  hideFlash(){
    setTimeout(() => $('.flash-message').slideUp(), 4000);
  }
}
