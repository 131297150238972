import {Controller} from "stimulus"
import axios from "axios"
import URI from "urijs"

export default class extends Controller {
  static targets = ["list", "setAllStatus"]

  initialize() {
    console.log('hello from list-load controller');
  }

  loadList() {
    let search_uri = new URI(location.search)
    this.getListRequest(this.listTarget, search_uri.search(true))
  }

  getListRequest(list, params) {
    const loader = document.querySelector('.loader');
    if (loader) {
      loader.style.display = 'block';
    }
    axios.get(list.getAttribute("data-path"), { params })
      .then(function (response) {
        list.innerHTML = response.data.html
        const countElement = document.querySelector('.inbox-txt')
        if (countElement) {
          countElement.textContent = response.data.count
        }
      })
      .catch(function(error) {
        console.log(error)
        return {}
      })
      .finally(() => {
        loader.style.display = 'none'
      })
}

  setAllStatusCheckboxes(e){
    var checked = this.setAllStatusTarget.checked
    var $checkboxes = $(".table-candidate .divTableCellStatus .vacancy-link input");
    $checkboxes.prop('checked', checked);
  }

  setAllStatusCheckboxesInVacancy(e){
    var checked = this.setAllStatusTarget.checked
    var $checkboxes = $(".table-vacancy .divTableCellStatus .vacancy-link input");
    $checkboxes.prop('checked', checked);
  }
}
