import {Controller} from "stimulus"
import URI from "urijs"
import axios from "axios"

export default class extends Controller {
  _getRequest(url) {
    return axios.get(url)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
        return {}
      });
  }

  _update_state(field, value) {
    let uri = new URI(location.search);
    uri.removeSearch('page');
    ((value === '') || (field, value) == ('page', 1)) ? uri.removeSearch(field) : uri.setSearch(field, value);
    history.replaceState({ turbolinks: {} }, "", uri.search() === '' ?  "?" : uri.search());
  }

  showMessage(text) {
    const message = document.createElement("div");
    message.textContent = text;
    message.style.position = "fixed";
    message.style.top = "50%";
    message.style.left = "50%";
    message.style.transform = "translate(-50%, -50%)";
    message.style.backgroundColor = "#fff";
    message.style.padding = "10px";
    message.style.border = "1px solid #ccc";
    message.style.borderRadius = "5px";
    message.style.boxShadow = "0 0 5px rgba(0, 0, 0, 0.3)";
    document.body.appendChild(message);

    setTimeout(() => {
        message.remove();
    }, 2000);
  }
}
