import {Controller} from "stimulus"
import URI from "urijs"
import axios from "axios"

export default class extends Controller {

  _getRequest(url) {
    return axios.get(url)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
        return {}
      });
  }

  _update_state(field, value) {
    let uri = new URI(location.search);
    uri.removeSearch('page');
    ((value === '') || (field, value) == ('page', 1)) ? uri.removeSearch(field) : uri.setSearch(field, value);
    history.replaceState({ turbolinks: {} }, "", uri.search() === '' ? "?" : uri.search());
  }

  setParamsCSV() {
    const generateCSVButton = document.querySelector("#generate-CSV")
    generateCSVButton.href = `candidates/download.csv${location.search}`
  }

  handleDateFilterSubmit(event) {
    event.preventDefault()

    const form = event.target
    const urlParams = new URLSearchParams(window.location.search)
    const formData = new FormData(form)

    for (const [key, value] of formData.entries()) {
      if (value) {
        urlParams.set(key, value)
      }
    }

    const newUrl = `${form.action}?${urlParams.toString()}`
    window.location.href = newUrl
  }
}
