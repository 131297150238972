import BaseController from "./base_controller"
import Tagify from '@yaireo/tagify'
import axios from "axios"

export default class extends BaseController {
  static targets = ['files', 'fileGroup', 'fileType', 'file', 'hidenInput', 'remove', 'filesName',
   'candidateLine', 'tags', 'companySelector', 'vacancySelector', 'recommenderSelector', 'recommenderEmail', 'candidateStatus'];

  initialize() {
    console.log('hello from candidate_line controller')
  }

  connect() {
    this._initTags()
    if(this.hasFilesNameTarget) {
      this.updateFilesField()
    }
    if(this.hasCompanySelectorTarget) {
      this.selectCompany()
    }
    if(this.hasRecommenderSelectorTarget) {
      this.selectRecommender()
    }
  }

  disconnect() {
    this._tagify.destroy()
  }

  async _initTags () {
    let input = this.tagsTarget
    const allTags = await this._getRequest(input.getAttribute("data-all-tags"))
    const candidateTags = await this._getRequest(input.getAttribute("data-candidate-tags"))

    input.value = candidateTags
    this._tagify = new Tagify(input, {
      whitelist : allTags,
      dropdown : {
          enabled: 1,
      }
    });
    let tagify = this._tagify;
    let maxChars = 20;
    this._tagify.on('input', function(e){
      if(e.detail.length > maxChars){
        tagify.addTags('.', 'clearInput');
        tagify.removeTag('.');
      }
    });
    this._tagify.DOM.input.classList.add('tagify__input--outside')
    this._tagify.DOM.scope.parentNode.insertBefore(this._tagify.DOM.input, this._tagify.DOM.scope)
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail
    this.candidateLineTarget.innerHTML = xhr.response
    let $checked = $(".table-candidate .divTableCellStatus input:checked");
    if($checked.length > 0){
      this.updateStatus($checked, $(this.candidateStatusTarget).find("option:selected").val());
    }
    $(".table-candidate .divTableCellStatus .checkmark").hide();
    $(".table-candidate  .divTableCellStatus .statusLine input").prop('checked', false);
    this.candidateLineTarget.classList.remove('editing')
    this._initTags()
    if(this.hasFilesNameTarget) {
      this.updateFilesField()
    }
    if(this.hasCompanySelectorTarget) {
      this.selectCompany()
    }
    if(this.hasRecommenderSelectorTarget) {
      this.selectRecommender()
    }
  }

  onPostSuccessInVacancy(event) {
    let [data, status, xhr] = event.detail
    this.candidateLineTarget.innerHTML = xhr.response
    let $checked = $(".table-vacancy .divTableCellStatus input:checked");
    if($checked.length > 0){
      this.updateStatus($checked, $(this.candidateStatusTarget).find("option:selected").val());
    }
    $(".table-vacancy .divTableCellStatus .checkmark").hide();
    $(".table-vacancy  .divTableCellStatus .statusLine input").prop('checked', false);
    this.candidateLineTarget.classList.remove('editing')
    this._initTags()
    if(this.hasFilesNameTarget) {
      this.updateFilesField()
    }
    if(this.hasCompanySelectorTarget) {
      this.selectCompany()
    }
    if(this.hasRecommenderSelectorTarget) {
      this.selectRecommender()
    }
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail
    event.target.innerHTML = xhr.response
    this._initTags()
    if(this.hasCompanySelectorTarget) {
      this.selectCompany()
    }
    if(this.hasRecommenderSelectorTarget) {
      this.selectRecommender()
    }
  }

  updateStatus($checked, status){
    $checked.each(function(index){
      var $parent_status_block = $(this).parents("td");
      var $form = $parent_status_block.find('form');
      $form.find("#candidate_status").val(status)
      $form.find('.btn').click();
    });
  }

  showStatusCheckboxes(event){
    $(".table-candidate .divTableCellStatus .checkmark").show();
  }

  showStatusCheckboxesInVacancy(event){
    $(".table-vacancy .divTableCellStatus .checkmark").show();
  }

  updateFilesField() {
    let candidateId = this.element.getAttribute("data-candidate")
    let currentPage = document.querySelector(".pagination .current").innerText.trim()
    let self = this

    const updateFiles = () => {
      axios.get("/candidates/" + candidateId + "/candidate_files?page=" + currentPage)
          .then(function (response) {
              self.filesNameTarget.innerHTML = response.data;
          })
          .catch(function () {
          });
  };

  if (this.filesNameTarget.innerHTML.includes("Processing")) {
      setTimeout(updateFiles, 60000);
  }
  }

  selectCompany() {
    let vacancySelector = this.vacancySelectorTarget;
    let vacancySelect = vacancySelector.querySelector('select');
    let value = this.companySelectorTarget.value;
    this.checkCompanyValue(vacancySelector, vacancySelect, value, this)
  }

  checkCompanyValue(vacancySelector, vacancySelect, value, self) {
  axios.get("/companies/" + value + "/company_vacancies.json")
    .then(function(response) {
      let data = response.data
      let selectedValue = vacancySelect.value
      vacancySelect.options.length = 0
      vacancySelect.add(new Option('Choose vacancy', ''))
      data.forEach(function(vacancy) {
        let optionText = vacancy.title + (vacancy.status ? ' ✅' : ' ❌')
        vacancySelect.add(new Option(optionText, vacancy.id))
      });
      vacancySelect.value = selectedValue
    })
    .catch(function(error) {
      console.error('Error fetching vacancies:', error)
    })
}


  selectRecommender() {
    let recommenderSelector = this.recommenderSelectorTarget;
    let value = recommenderSelector.value;
    if (value != "")  {
      this.recommenderEmailTarget.style.display = 'none';
      this.recommenderEmailTarget.querySelector('input').value = "";
    } else {
      this.recommenderEmailTarget.style.display = 'block';
    }
  }

  //file
  addFileGroup(e){
    e.preventDefault();
    let file_fields_clone = this.fileGroupTarget.cloneNode(true)
    let selected_options = file_fields_clone.querySelector('.file-type select').selectedOptions
    for(let i = 0; i < selected_options.length; i++){
      selected_options[i].removeAttribute('selected')
    }
    file_fields_clone.style.display = 'flex'
    this.filesTarget.append(file_fields_clone.cloneNode(true))
    this._rehash_indexses()
  }

  removeFileGroup(e){
    e.preventDefault();
    e.target.parentNode.querySelector('.btn-delete input[id]').checked = true
    e.target.parentNode.style.display = 'none'
  }

  _rehash_indexses(){
    let field_file_id = this.fileTarget.getAttribute('id')
    let field_file_name = this.fileTarget.getAttribute('name')
    let field_fileType_id = this.fileTypeTarget.getAttribute('id')
    let field_fileType_name = this.fileTypeTarget.getAttribute('name')
    let field_checkbox_id = this.removeTarget.getAttribute('id')
    let field_checkbox_name = this.removeTarget.getAttribute('name')
    for(let i=0; i < this.fileGroupTargets.length; i++) {
      this.fileTypeTargets[i].setAttribute('id', field_fileType_id.replace('0', i))
      this.fileTypeTargets[i].setAttribute('name', field_fileType_name.replace('0', i))
      this.fileTargets[i].setAttribute('id', field_file_id.replace('0', i))
      this.fileTargets[i].setAttribute('name', field_file_name.replace('0', i))
      this.hidenInputTargets[i].setAttribute('id', field_file_id.replace('0', i))
      this.hidenInputTargets[i].setAttribute('name', field_file_name.replace('0', i))
      this.removeTargets[i].setAttribute('id', field_checkbox_id.replace('0', i))
      this.removeTargets[i].setAttribute('name', field_checkbox_name.replace('0', i))
    }
  }

}
