import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ["customFields", "customFieldBlock", "field", "remove", "category", "specialty", "specialtyId"];

  initialize(){
    console.log('hello from vacancy form controller');
    this.cloneCustomField();
  }

  loadSpecialties() {
    let category = this.categoryTarget.value

    axios.get(`/specialties/list_specialties?category=${category}`)
      .then(response => {
        let options = response.data.map(specialty => `<option value="${specialty._id}">${specialty.title}</option>`).join("")
        this.specialtyTarget.innerHTML = options
      })
      .catch(error => console.error(error))
  }

  cloneCustomField() {
    this.custom_field_clone = this.customFieldBlockTarget.cloneNode(true);
    this.custom_field_clone.getElementsByTagName('input')[0].value = "";
    this.field_id = this.fieldTarget.getAttribute('id');
    this.field_name = this.fieldTarget.getAttribute('name');
    this.field_checkbox_id = this.removeTarget.getAttribute('id');
    this.field_checkbox_name = this.removeTarget.getAttribute('name');
  }

  addCustomField(e){
    e.preventDefault();
    this.customFieldsTarget.append(this.custom_field_clone.cloneNode(true));
    this._rehash_indexses();
  }

  removeCustomField(e){
    e.preventDefault();
    e.target.parentNode.querySelector('.btn-delete input[id]').checked = true;
    e.target.parentNode.style.display = 'none';
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    event.target.innerHTML = xhr.response;
  }

  sentClosed_Email(e){
    var send_email = window.confirm("Ви впевнені? Це відправить електронний лист про закриття вакансії кандидатам.");

    if (send_email) {
      e.target.disabled = true;

      let form = e.currentTarget.closest('form');
      let formData = new FormData(form);
      let vacancyId = e.currentTarget.dataset.id;
      let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
      
      axios.patch(`/vacancies/${vacancyId}/closed_emails`, formData)
        .then(function (response) {
          console.log(response);
          if (response.data && response.data.message) {
            alert(response.data.message);
          } 
        })
        .catch(function (error) {
          console.log(error);
          alert("Сталася помилка. Будь ласка спробуйте пізніше");
        });
    }
    else {
      e.target.checked = false;
      e.preventDefault();
    }
  }

  changeStatus(e) {
    if (!e.target.checked) {
        var send_email = window.confirm("Ви впевнені, що хочете закрити вакансію? Це надішле відповідне сповіщення усім кандидатам, що є на розгляді.");

        if (send_email) {
            e.target.disabled = true;

            let form = e.currentTarget.closest('form');
            let formData = new FormData(form);
            let vacancyId = e.currentTarget.dataset.id;
            let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

            axios.patch(`/vacancies/${vacancyId}/change_status`, formData)
                .then(function (response) {
                    console.log(response);
                    if (response.data && response.data.message) {
                        alert(response.data.message);
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Сталася помилка. Будь ласка спробуйте пізніше");
                });
        } else {
            e.target.checked = true;
            e.preventDefault();
        }
    } else {
        var restore_vacancy = window.confirm("Щоб відновити вакансію, натисніть кнопку Update, коли перемикач увімкнений.");
        
        if (!restore_vacancy) {
            e.target.checked = false; 
            e.preventDefault(); 
        } else {
        }
    }
  }

  _rehash_indexses(){
    for(let i=0; i < this.customFieldBlockTargets.length; i++) {
        this.fieldTargets[i].setAttribute('id', this.field_id.replace('0', i));
        this.fieldTargets[i].setAttribute('name', this.field_name.replace('0', i));
        this.removeTargets[i].setAttribute('id', this.field_checkbox_id.replace('0', i));
        this.removeTargets[i].setAttribute('name', this.field_checkbox_name.replace('0', i));
    }
  }

}
