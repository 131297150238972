import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['role', 'company']
  initialize() {
    console.log('hello from user form controller');
    this.checkRole();
  }

  selectRole() {
    this.checkRole();
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    event.target.innerHTML = xhr.response;
    this.checkRole();
  }

  checkRole() {
    if (this.roleTarget.value == "employer") {
      this.companyTarget.disabled = false;
    } else {
      this.companyTarget.disabled = true;
      this.companyTarget.value = ""
    }
  }
}
