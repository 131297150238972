//  TODO Will be removed if future

import BaseController from "./base_controller"
import Tagify from '@yaireo/tagify'

export default class extends BaseController {
  static targets = ['tags', 'files', 'fileFields', 'select', 'file', 'hideInput', 'remove'];
  initialize() {
    console.log('hello from candidate edit controller');
    this.cloneFileField();
  }
  connect() {
    this._initTags()
  }
  disconnect() {
    this._tagify.destroy()
  }

  async _initTags () {
    if (document.querySelectorAll("tags.tagify").length < 1) {
      let input = this.tagsTarget
      const allTags = await this._getRequest(input.getAttribute("data-all-tags"))
      const candidateTags = await this._getRequest(input.getAttribute("data-candidate-tags"))

      input.value = candidateTags
      this._tagify = new Tagify(input, {
          whitelist : allTags,
          dropdown : {
              enabled: 1,
          }
      });
      this._tagify.DOM.input.classList.add('tagify__input--outside');
      this._tagify.DOM.scope.parentNode.insertBefore(this._tagify.DOM.input, this._tagify.DOM.scope);
    }
  }

  cloneFileField() {
    this.file_fields_clone = this.fileFieldsTarget.cloneNode(true);
    let selected_options = this.file_fields_clone.getElementsByTagName('select')[0].selectedOptions;
    for(let i = 0; i < selected_options.length; i++){
      selected_options[i].removeAttribute("selected");
    }
    this.field_file_id = this.fileTarget.getAttribute('id');
    this.field_file_name = this.fileTarget.getAttribute('name');
    this.field_select_id = this.selectTarget.getAttribute('id');
    this.field_select_name = this.selectTarget.getAttribute('name');
    this.field_checkbox_id = this.removeTarget.getAttribute('id');
    this.field_checkbox_name = this.removeTarget.getAttribute('name');
  }

  addFileFields(e){
    e.preventDefault();
    this.filesTarget.append(this.file_fields_clone.cloneNode(true));
    this._rehash_indexses();
  }

  removeFileFields(e){
    e.preventDefault();
    e.target.parentNode.querySelector('.btn-delete input[id]').checked = true;
    e.target.parentNode.style.display = 'none';
  }

  _rehash_indexses(){
    for(let i=0; i < this.fileFieldsTargets.length; i++) {
      this.selectTargets[i].setAttribute('id', this.field_select_id.replace('0', i));
      this.selectTargets[i].setAttribute('name', this.field_select_name.replace('0', i));
      this.fileTargets[i].setAttribute('id', this.field_file_id.replace('0', i));
      this.fileTargets[i].setAttribute('name', this.field_file_name.replace('0', i));
      this.hideInputTargets[i].setAttribute('id', this.field_file_id.replace('0', i));
      this.hideInputTargets[i].setAttribute('name', this.field_file_name.replace('0', i));
      this.removeTargets[i].setAttribute('id', this.field_checkbox_id.replace('0', i));
      this.removeTargets[i].setAttribute('name', this.field_checkbox_name.replace('0', i));
    }
  }
}
