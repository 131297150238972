// TODO remove later?
import BaseController from "./base_controller"
import axios from "axios"
import URI from "urijs"

export default class extends BaseController {

  initialize() {
    console.log('hello from vacancies index controller');
  }
  connect() {

  }
  disconnect() {
  }

  sendEmployerMail(e){
    e.preventDefault();
    let url = e.target.href;
    axios.get(url)
      .then(function (response) {
        $('.flash-message').append(response.data).delay().fadeIn();
      })
      .catch(function (error) {
        console.log(error);
      });
    this.hideFlash();
  }

  hideFlash(){
    setTimeout(() => $('.flash-message').slideUp(), 4000);
  }

  copy(e) {
    e.preventDefault()
    const vacancyId = e.target.dataset.id.replace(/"/g, '')
    navigator.clipboard.writeText(vacancyId)
    this.showMessage("ID вакансії скопійовано до буферу обміну")
  }
}
