import BaseController from "./base_controller"

export default class extends BaseController {
  initialize() {
    console.log('hello from candidates index controller');
  }

  showForm(e){
    e.preventDefault();
    document.querySelectorAll('td.editing').forEach(function (editing_td) {
      editing_td.classList.remove('editing');
    });
    e.target.closest('td').classList.add('editing');
  }
  
  generatePDF(e) {
    e.preventDefault();
    const candidateId = e.currentTarget.dataset.id.replace(/"/g, '')
  fetch(`/candidates/${candidateId}/generate_pdf`, {
    method: 'GET',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Помилка при генерації PDF')
    }
  })
  .then(data => {
    this.showMessage(data.message)
  })
  .catch(error => {
    console.error('Помилка:', error)
  });
}

  statusHistory(e) {
  e.preventDefault();
  const candidateId = e.currentTarget.dataset.id.replace(/"/g, '');
  fetch(`/candidates/${candidateId}/history_tracks`, {
    method: 'GET',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Помилка при отриманні історії змін статусу');
    }
    return response.text();
  })
  .then(data => {
    this.showHistory(data);
  })
  .catch(error => {
    console.error('Помилка:', error);
  });
}

  showHistory(text) {
    const message = document.createElement("div");
    message.classList.add("status-history-message");
    message.innerHTML = `
    <div class="status-history-content">${text}</div>
    <button class="close-button">Закрити</button>
  `;
    message.style.position = "fixed";
    message.style.top = "50%";
    message.style.left = "50%";
    message.style.transform = "translate(-50%, -50%)";
    message.style.backgroundColor = "#fff";
    message.style.padding = "10px";
    message.style.border = "1px solid #ccc";
    message.style.borderRadius = "5px";
    message.style.boxShadow = "0 0 5px rgba(0, 0, 0, 0.3)";
    message.style.maxHeight = "80%";
    message.style.overflowY = "auto";
    message.style.zIndex = "10000";
    document.body.appendChild(message);

    const closeButton = message.querySelector('.close-button');
    closeButton.addEventListener('click', () => {
      message.remove();
    });
  }
}
