import {Controller} from "stimulus"
import HelpersController from "./helpers_controller"
import URI from "urijs"

export default class extends Controller {

  initialize() {
    console.log('hello from pagination controller');
    this.helpers = new HelpersController()
    this.setListController()
  }

  page(e) {
    e.preventDefault()
    this.helpers._update_state('page', e.target.textContent.trim())
    this.listLoadController.loadList()
  }

  prevPage(e){
    e.preventDefault()
    let prev_page = new URI(e.target.href)
    let prev_page_params = prev_page.search(true)
    this.helpers._update_state('page', prev_page_params.page || 1)
    this.listLoadController.loadList()
  }

  nextPage(e){
    e.preventDefault()
    let next_page = new URI(e.target.href)
    let next_page_params = next_page.search(true)
    this.helpers._update_state('page', next_page_params.page)
    this.listLoadController.loadList()
  }

  setListController(){
    setTimeout(() => {
      this.listLoadController = this.application.getControllerForElementAndIdentifier(
            document.getElementsByClassName('list')[0],
            "components--list-load"
          );
    }, 0)
  }

}
