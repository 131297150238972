import {Controller} from "stimulus"
import HelpersController from "./helpers_controller"

export default class extends Controller {

  initialize() {
    console.log('hello from sort controller');
    this.helpers = new HelpersController()
    this.setListController()
  }

  sortInterviewTime(e) {
    this.helpers._update_state('interview_time', e.target.checked ? "asc" : "")
    this.listLoadController.loadList()
  }

  sortSalary(e) {
    this.helpers._update_state('salary', e.target.checked ? "asc" : "")
    this.listLoadController.loadList()
  }

  sortTotal(e) {
    this.helpers._update_state('total', e.target.checked ? "desc" : "")
    this.listLoadController.loadList()
  }

  setListController(){
    setTimeout(() => {
      this.listLoadController = this.application.getControllerForElementAndIdentifier(
            document.getElementsByClassName('list')[0],
            "components--list-load"
          );
    }, 0)
  }

}
