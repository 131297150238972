import {Controller} from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ["company", "vacancy", "createButton"];

  connect() {
    console.log('hello from inbox index controller');
  }

  disconnect() {
  }

  selectCompany() {
    this.vacancyTarget.innerHTML = '<option value="">Choose vacancy</option>';
    if (this.companyTarget.value != "") {
      this._loadAndFillVacancies(this.companyTarget.value, this.vacancyTarget);
    } else {
      this.createButtonTarget.style.visibility = "hidden";
    }
  }

  selectVacancy() {
    if (this.vacancyTarget.value != "") {
      this.createButtonTarget.disabled = false;
      this.createButtonTarget.style.visibility = "visible";
    }
  }

  createCandidate() {
    this._creatingCandidate(this.data.get("inbox-id"), this.vacancyTarget.value);
  }

  displayMore(e) {
    if (e.target.localName == "select") return false

    const toggle = e.target.parentNode.classList.contains('inbox-letter') ||
      e.target.classList.contains('inbox-letter');

    if (this.data.has('inbox-id') && toggle) {
      this.data.element.classList.toggle('js-show');
      this.data.element.querySelector('.show-sender').classList.toggle('show-inbox')
    }
  }

  _loadAndFillVacancies(company_id = "", vacancies_select) {
    if (company_id != "") {
      let url = this.data.get("company-vacancies-url").replace('-id-', company_id);
      axios.get(url)
        .then(function (response) {
          vacancies_select.disabled = false;
          vacancies_select.options[0].disabled = true;
          response.data.forEach((element, index) => {
            const isActive = element.status === true;
            const optionText = isActive ? `${element.title} ✅` : `${element.title} ❌ `;
            vacancies_select.options[index + 1] = new Option(optionText, element.id);
          })
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  _creatingCandidate(inbox_id, vacancy_id) {
    let url = this.data.get("create-url").replace('-id-', inbox_id);
    let csrf_token = document.querySelector('meta[name="csrf-token"]').content;
    let inbox_element = this.element;

    axios.post(url, {vacancy: vacancy_id}, {headers: {'X-CSRF-Token': csrf_token}})
      .then(function (response) {
        console.log(response);
        inbox_element.remove();
      })
      .catch(function (error) {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("Something went wrong");
        }
      });
  }
}
